import { GlobalNotificationQuery } from "../../../generated/graphql";

export const globalNotification: GlobalNotificationQuery["globalNotification"] =
  {
    notificationId: "transfervideo-2206104",
    _allNotificationMetaLocales: [
      {
        value:
          "Ready für die neue kaufmännische Grundbildung? Dein Guide für die Praxis!",
        locale: "de",
      },
      {
        value:
          "Prêt pour la Nouvelle Formation Commerciale de Base ? Votre guide pratique!",
        locale: "fr",
      },
      {
        value:
          "Pronti per la nuova formazione commerciale di base? La vostra guida per la parte pratica!",
        locale: "it",
      },
    ],
    _allNotificationTitleLocales: [
      { value: "Notifikation", locale: "de" },
      {
        value:
          "Prêt pour la Nouvelle Formation Commerciale de Base ? Votre guide pratique!",
        locale: "fr",
      },
      {
        value:
          "Pronti per la nuova formazione commerciale di base? La vostra guida per la parte pratica!",
        locale: "it",
      },
    ],
    _allNotificationContentLocales: [
      { value: "", locale: "de" },
      { value: "", locale: "fr" },
      { value: "", locale: "it" },
    ],
    _allNotificationActiveLocales: [
      { locale: "de", value: false },
      { locale: "fr", value: false },
      { locale: "it", value: false },
    ],
    notificationLink: { __typename: "ContentPageRecord", id: "92918229" },
    ignoredPages: [
      { __typename: "BlogPostRecord", id: "92912106" },
      { __typename: "BlogPostRecord", id: "92912102" },
      { __typename: "ContentPageRecord", id: "92918134" },
      { __typename: "ContentPageRecord", id: "92918142" },
      { __typename: "ContentPageRecord", id: "92918143" },
      { __typename: "ContentPageRecord", id: "92918144" },
      { __typename: "ContentPageRecord", id: "92918145" },
      { __typename: "ContentPageRecord", id: "92918146" },
      { __typename: "ContentPageRecord", id: "92918147" },
      { __typename: "ContentPageRecord", id: "92918149" },
      { __typename: "ContentPageRecord", id: "92918150" },
      { __typename: "ContentPageRecord", id: "92918151" },
      { __typename: "ContentPageRecord", id: "92918154" },
      { __typename: "ContentPageRecord", id: "92918155" },
      { __typename: "ContentPageRecord", id: "92918158" },
      { __typename: "ContentPageRecord", id: "92918159" },
      { __typename: "ContentPageRecord", id: "92918160" },
      { __typename: "ContentPageRecord", id: "92918161" },
      { __typename: "ContentPageRecord", id: "92918162" },
      { __typename: "ContentPageRecord", id: "92918163" },
      { __typename: "ContentPageRecord", id: "92918164" },
      { __typename: "ContentPageRecord", id: "92918165" },
      { __typename: "ContentPageRecord", id: "92918166" },
      { __typename: "ContentPageRecord", id: "92918167" },
      { __typename: "ContentPageRecord", id: "92918168" },
      { __typename: "ContentPageRecord", id: "92918169" },
      { __typename: "ContentPageRecord", id: "92918170" },
      { __typename: "ContentPageRecord", id: "92918171" },
      { __typename: "ContentPageRecord", id: "92918172" },
      { __typename: "ContentPageRecord", id: "92918173" },
      { __typename: "ContentPageRecord", id: "92918174" },
      { __typename: "ContentPageRecord", id: "92918175" },
      { __typename: "ContentPageRecord", id: "92918176" },
      { __typename: "ContentPageRecord", id: "92918177" },
      { __typename: "ContentPageRecord", id: "92918178" },
      { __typename: "ContentPageRecord", id: "92918179" },
      { __typename: "ContentPageRecord", id: "92918180" },
      { __typename: "ContentPageRecord", id: "92918181" },
      { __typename: "ContentPageRecord", id: "92918182" },
      { __typename: "ContentPageRecord", id: "92918183" },
      { __typename: "ContentPageRecord", id: "92918184" },
      { __typename: "ContentPageRecord", id: "92918187" },
      { __typename: "ContentPageRecord", id: "92918188" },
      { __typename: "ContentPageRecord", id: "92918191" },
      { __typename: "ContentPageRecord", id: "92918192" },
      { __typename: "ContentPageRecord", id: "92918193" },
      { __typename: "ContentPageRecord", id: "92918194" },
      { __typename: "ContentPageRecord", id: "92918195" },
      { __typename: "ContentPageRecord", id: "92918197" },
      { __typename: "ContentPageRecord", id: "92918198" },
      { __typename: "ContentPageRecord", id: "92918199" },
      { __typename: "ContentPageRecord", id: "92918201" },
      { __typename: "ContentPageRecord", id: "92918202" },
      { __typename: "ContentPageRecord", id: "92918207" },
      { __typename: "ContentPageRecord", id: "92918208" },
      { __typename: "ContentPageRecord", id: "92918209" },
      { __typename: "ContentPageRecord", id: "92918212" },
      { __typename: "ContentPageRecord", id: "92918221" },
      { __typename: "ContentPageRecord", id: "92918222" },
      { __typename: "ContentPageRecord", id: "92918223" },
      { __typename: "ContentPageRecord", id: "92918226" },
      { __typename: "ContentPageRecord", id: "92918229" },
      { __typename: "ContentPageRecord", id: "92918231" },
      { __typename: "ContentPageRecord", id: "92918233" },
      { __typename: "ContentPageRecord", id: "92918234" },
      { __typename: "ContentPageRecord", id: "92918235" },
      { __typename: "ContentPageRecord", id: "92918236" },
      { __typename: "ContentPageRecord", id: "92918237" },
      { __typename: "ContentPageRecord", id: "92918240" },
      { __typename: "ContentPageRecord", id: "120332233" },
      { __typename: "ContentPageRecord", id: "120333701" },
      { __typename: "ContentPageRecord", id: "120333706" },
      { __typename: "ContentPageRecord", id: "120414837" },
    ],
  } as any;
